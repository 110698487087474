import React from "react"
import styled from 'styled-components'
import media from '../../styles/media'
import { Reveal, Tween } from 'react-gsap'
import { isMobile } from 'react-device-detect'

const EnvironmentQuoteCon = styled.div`
  margin: 6em 6.2vw;

  .heading, p {
    font-size: 28px;
    line-height: 50px;
    font-family: 'Merriweather', serif;
     
    letter-spacing: 0.02em;
    color: black;
  }

  ${media.laptop`
    width: auto;
    height: 100vh;
    margin: auto 0 auto 250px;
    display: flex;
    flex-direction: column;
    align-items: center; 
    justify-content: center;
    .inner {
      width: 490px;
      max-width: 600px;
      margin: 0 auto;
      color: #323232;
      p {
        font-size: 40px;
        line-height: 64px;
        letter-spacing: 0.01em;
        font-family: 'Merriweather', serif;
        .line {
          margin: 0;
          height: 57px;
          overflow: hidden;
          transform: skewY(0);
        }
      }
    }

  `}
`

const EnvironmentQuote = () => {

  return ( 
    <div id="foo">
    <EnvironmentQuoteCon>
      <Reveal>
        {!isMobile && (
          <div className="inner">
            <p>
              <div className="line">
                <Tween from={{  }} duration={1}>
                <div className="inner"></div>
                </Tween>
              </div>
              <div className="line">
                <Tween from={{ marginTop: "80px", transform: `skewY(7deg)` }} duration={1} delay={.2}>
                <div className="inner">We’re taking </div>
                </Tween>
              </div>
              <div className="line">
                <Tween from={{ marginTop: "80px", transform: `skewY(7deg)` }} duration={1} delay={.6}>
                <div className="inner">sustainability into our </div>
                </Tween>
              </div>
              <div className="line">
                <Tween from={{ marginTop: "80px", transform: `skewY(7deg)` }} duration={1} delay={1}>
                <div className="inner">own hands and </div>
                </Tween>
              </div>
              <div className="line">
                <Tween from={{ marginTop: "80px", transform: `skewY(7deg)` }} duration={1} delay={1.4}>
                <div className="inner">reducing our footprint.</div>
                </Tween>
              </div>
            </p>
          </div>
        )}
        {isMobile && (
          <Tween from={{ opacity: 0, paddingTop: '5vh' }} duration={1}>
            <div className="text">
              <p className="heading">We’re taking sustainability into our own hands and reducing our footprint.</p>
            </div>
          </Tween>
        )}
      </Reveal>
    </EnvironmentQuoteCon>
    </div>
  )
}

export default EnvironmentQuote
