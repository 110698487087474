import React from "react"
import styled from 'styled-components'
import media from '../../styles/media'
import { Reveal, Tween } from 'react-gsap'

const EnvironmentBlurbTwoCon = styled.div`
  margin: 6em 6.2vw;

  .heading {
    font-size: 20px;
    line-height: 40px;
    font-family: 'Merriweather', serif;
     
    color: black;
  }
  .body {
    font-size: 15px;
    line-height: 30px;
     
  }

  ${media.laptop`
    width: auto;
    height: 100vh;
    margin: auto 150px;
    display: flex;
    flex-direction: column;
    align-items: center; 
    justify-content: center;

    .text {
      width: 500px;
      margin: auto 150px;
    }

    .heading {
      font-size: 25px;
      line-height: 40px;
      letter-spacing: 0.01em;
    }
    .body {
      margin-top: 30px;
      max-width: 500px;
      font-size: 16px;
      line-height: 26px;
    }

  `}
`

const EnvironmentBlurbTwo = () => {

  return ( 
    <EnvironmentBlurbTwoCon>
      <div className="text">
        <Reveal>
          <Tween from={{ opacity: 0, paddingTop: '5vh' }} duration={1}>
            <p className="heading">An eco-friendly laundry process</p>
          </Tween>
        </Reveal>
        <Reveal>
          <Tween from={{ opacity: 0, paddingTop: '5vh' }} duration={1} delay={.5}>
            <p className="body">
              Our hi-tech washing technology saves both energy and water consumption. Without comprising our high hygiene standards, our locally sourced eco-friendly laundry detergents harness enzymes and ozone technology that reduce the amount of bleach required and allows for washing at lower temperatures. We’ve managed to reduce our water usage to only 8 litres per kilogram.
            </p>
          </Tween>
        </Reveal>
      </div>
    </EnvironmentBlurbTwoCon>
  )
}

export default EnvironmentBlurbTwo
