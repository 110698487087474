import React, { useRef, useEffect } from "react"
import { graphql, useStaticQuery } from 'gatsby'
import styled, { keyframes } from 'styled-components'
import media from '../../styles/media'
import Img from 'gatsby-image'
import ScrollDarkDiv from '../scroll-dark'
import { Reveal, Tween } from 'react-gsap'

import Line from '../../images/line.png'
import Env2 from '../../images/env2.png'

const Rotate = keyframes`
  to {
    top: 45vh
  }
`

const HeroCon = styled.div`
  min-height: 600px;
  min-width: 100vw;
  color: black;
  top: 0;
  position: relative;

  .bg {
    width: 100vw ;
    height: 90vh;
    min-height: 600px;
  }

  .text {
    padding: 10vh 5vw;
    margin-top: 10vh;
  }

  span {
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
  }

  p {
    font-size: 36px;
    line-height: 45px;
    font-weight: normal;
    max-width: 80vw;
    font-family: 'Merriweather', serif;
     
  }

  .line {
    position: absolute;
    top: 30vh;
    left: 85vw;
    height: 150px;

    animation: ${Rotate} 3s ease-out forwards;
    animation-play-state: paused;
    animation-delay: calc(var(--scroll) * -0.5s);
  }

  .env-image {
    display: none;
  }

  figure {
    width: 220px;
    height: 220px;
    position: relative;
    margin: 0 0 0 150px;

    .figure {
      width: 220px;
      overflow: hidden;
      height: 220px;
    }
    
    .hero-image {
      width: 220px;
      height: 220px;
    }
    img {
      max-height: 220px;
    }
    
    figcaption {
      display: block;
      font-size: 10px;
      line-height: 10px;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      height: 220px;
      max-height: 220px;
      text-align: left;
      position: absolute;
      bottom: 0px;
      margin-left: -20px;
      margin-right: 10px;
      z-index: 0;
      opacity: 1;
      -ms-writing-mode: tb-rl;
      -webkit-writing-mode: tb-rl;
      writing-mode: tb-rl;
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg) translateY(0%);
      user-select: none;
      span {
        color: #64BF60;
        font-size: 10px;
        line-height: 10px;
      }
    }
  }

  ${media.mobileL`
    min-height: 400px;
    figure {
    width: 220px;
    height: 220px;
    position: relative;
    margin: 0 0 0 250px;
    }

  .line {
    position: absolute;
    top: 35vh;
    left: 85vw;
    height: 150px;
  }
    .bg {
      width: 90vw ;
      height: 90vh;
      min-height: 400px;
    }

  `}

  ${media.laptop`
    width: 160vw;
    height: 100vh;
    padding: auto;
    margin-top: 10vh;

    .bg {
      width: 88vw ;
      height: 100vh;
      display: flex;
      flex-direction: column;
      align-items: left; 
      justify-content: center;
    }

    .text {
      margin-left: 150px;
      margin-top: 0;
      padding: 0;
      text-align: left;
    }

    span {
      font-size: 14px;
      line-height: 16px;
      text-transform: uppercase;
    }

    p {
      width: 500px;
      font-size: 45px;
      line-height: 1.5;
      letter-spacing: 0.01em;
      padding: 0;
    }

    .line {
      position: absolute;
      top: 0;
      left: 1150px;
      height: 250px;
    }


    .env-image {
      position: absolute;
      display: block;
      top: 0vh;
      left: 1350px;
      height: 300px;
    }

    figure {
      position: absolute !important;
      top: 30vh;
      left: 650px;
      height: 270px;
      width: 320px;
      margin: 0;

      .figure {
        width: 300px;
        height: 300px;
        overflow: hidden;
      }
      
      .hero-image {
        width: 270px;
        height: 270px;
      }
      img {
        width: 300px;
        height: 300px;
        max-height: calc(100vh - 60px);
      }
      
      figcaption {
        display: block;
        font-size: 10px;
        line-height: 10px;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        height: 100%;
        max-height: 300px;
        text-align: left;
        position: absolute;
        bottom: -0px;
        left: -15px;
        margin-right: 0px;
        z-index: -1;
        -ms-writing-mode: tb-rl;
        -webkit-writing-mode: tb-rl;
        writing-mode: tb-rl;
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg) translateY(0%);
        user-select: none;
        span {
          color: #64BF60;
        font-size: 10px;
        line-height: 10px;
        }
      }
    }
  `}

  ${media.laptopL`
    width: 110vw;
    margin-top: 20vh;
    figure {
      top: 35vh;
    }
    p {
      width: 650px;
      font-size: 54px;
      line-height: 75px;
      letter-spacing: 0.01em;
      padding: 0;
    }
  `}
`

const ScrollCon = styled.div`
  ${media.laptop`
    position: "sticky";
    width: 100vw; 
    height: 100vh; 
    margin-left: 0; 
    margin-top: -110vh;
    z-index: -1;
  `}
  ${media.laptopL`
    position: "sticky";
    width: 100vw; 
    height: 100vh; 
    margin-left: 0; 
    margin-top: -120vh;
    z-index: -1;
  `}
`

const Hero = () => {
  const divRef = useRef(null)

  const data = useStaticQuery(
    graphql`
      query {
        contentfulWebsiteImage(department: {eq: "Cutting room"}) {
          department
          description
          imageFile {
            fluid(maxWidth: 980) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    `
  )

  
  const onWheel = e => {
    var outer = document.getElementById('container')

    if (typeof window !== 'undefined') {
      if (outer) {
        document.body.style.setProperty(
          "--scroll",
          (outer.scrollLeft- 200)/200
        );
      }
    } else {
      document.body.style.setProperty(
        "--scroll",
        window.scrollY/10
      );
    }

  }

  return (
    <>
    <div onWheel={onWheel} ref={divRef}>
      <HeroCon >
        <Reveal>
          <Tween from={{ opacity: 0, paddingTop: '37vh' }} duration={1}>
            <div className="text">
              <p>Looking out for the environment is at the core of our business</p>
            </div>
          </Tween>
        </Reveal>
        <figure>
          <div className="figure">
            <Tween from={{ transform: 'scale(1.2)' }} duration={1} delay={.5}>
              <Img fluid={data.contentfulWebsiteImage.imageFile.fluid} alt="divider" className="hero-image" />
            </Tween>
          </div>
          <Tween from={{ marginLeft: '30px', opacity: 0 }} duration={1}>
            <figcaption><span>{data.contentfulWebsiteImage.department} </span> / {data.contentfulWebsiteImage.description}</figcaption>
          </Tween>
        </figure>

        <img src={Line} alt="line" className="line" />

        <figure className="env-image" >
          <div className="figure">
            <Tween from={{ transform: 'scale(1.2)' }} duration={1} delay={.5}>
              <img src={Env2} alt="divider" />
            </Tween>
          </div>
          <Tween from={{ marginLeft: '30px', opacity: 0 }} duration={1}>
            <figcaption><span>WASHING </span> / WASHING BEING OFF LOADED</figcaption>
          </Tween>
        </figure>

      </HeroCon> 

      <ScrollCon>
        <ScrollDarkDiv page="env" />
      </ScrollCon>
    </div>
    </>
  )
}

export default Hero
