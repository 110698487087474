import React from "react"
import styled from 'styled-components'
import media from '../../styles/media'
import { graphql, useStaticQuery } from 'gatsby'
import { Reveal, Tween } from 'react-gsap'
import Img from 'gatsby-image'

const EnvironmentCaptionedCon = styled.div`
  margin: 0 0 5em;

  figure {
    margin: 0 6vw;
    position: relative;
    height: 570px;

    .figure {
      overflow:  hidden;
      height: 570px;
    }

    .hero-image, .imgDiv {
      height:550px;
      margin: 0;
    }
    
    figcaption {
      text-align: center;
      text-transform: uppercase;
      font-size: 10px;
      font-weight: normal;
      line-height: 10px;
      margin-bottom: 10px;
      span {
        color: #64BF60;
      }
    }
  }

  ${media.mobileL`
    height: 500px;
    width: 90vw;
    margin: 0 5vw;
    display: flex;
    justify-content: center;
    figure {
      position: relative;
      width: 90vw;

      .figure {
        height: 500px;
        overflow: hidden;
        width: 90vw;
      }

      .hero-image, .imgDiv {
        height: 400px;
        width: 90vw;
      }
      
      figcaption {
        text-align: center;
        text-transform: uppercase;
        font-size: 10px;
        line-height: 10px;
        margin-bottom: 10px;
        font-weight: normal;
        span {
          color: #64BF60;
        }
      }
    }
  `}

  ${media.laptop`
    height: 100vh;
    width: auto;
    margin: 0 150px 0 0;

    .text {
      transform: rotate(270deg);
      width: 300px;
      height: 10px;
      p {
        margin-top: 100px;
      }
    }

    .imgCon {
      padding: 90px 0;
        width: auto;
      figure {
        width: auto;
        margin: 0;
        max-height: calc(100vh - 180px);
        position: relative;

        .figure {
          width: 350px;
          height: calc(100vh - 180px);
          max-height: calc(100vh - 180px);
          overflow: hidden;
        }

        .hero-image, .imgDiv {
          max-height: calc(100vh - 180px);
          height: calc(100vh - 180px);
          width: 36vw;
          min-width: 400px;
        }
        
        figcaption {
          display: block;
          font-size: 10px;
          line-height: 10px;
          font-weight: normal;
          letter-spacing: 0.08em;
          text-transform: uppercase;
          height: 100%;
          max-height: 460px;
          text-align: left;
          position: absolute;
          bottom: 50px;
          left: -30px;
          z-index: -1;
          -ms-writing-mode: tb-rl;
          -webkit-writing-mode: tb-rl;
          writing-mode: tb-rl;
          -webkit-transform: rotate(180deg);
          transform: rotate(180deg) translateY(0%);
          user-select: none;
          span {
            color: #64BF60;
          }
        }
      }
    }
  `}

  ${media.laptopL`
    .imgCon {
      padding: 90px 0;
      width: auto;
      figure {
        width: auto;
        margin: 0;
        max-height: calc(100vh - 180px);
        position: relative;

        .figure {
          width: 450px;
          height: calc(100vh - 180px);
          max-height: calc(100vh - 180px);
          overflow: hidden;
        }
      }
    }
  `}
`

const EnvironmentCaptioned = () => {
  const data = useStaticQuery(graphql`
    query {
      contentfulWebsiteImage(identifier: {eq: "env captioned 1"}, sys: {}) {
        imageFile {
          fluid(maxWidth: 980) {
            ...GatsbyContentfulFluid
          } 
        }
        department
        description
      }
    }
  `)

  return ( 
    <EnvironmentCaptionedCon>
      <div className="imgCon">
        <Reveal>
          <figure>
            <Tween from={{ left: '30px' }} duration={1}>
              <figcaption><span>{data.contentfulWebsiteImage.department} </span> / {data.contentfulWebsiteImage.description}</figcaption>
            </Tween>
            <div className="figure">
              <Tween from={{ transform: 'scale(1.2)' }} duration={1} delay={.5}>
                <div className="imgDiv">
                  <Img fluid={data.contentfulWebsiteImage.imageFile.fluid} alt="divider" className="hero-image" />
                </div>
              </Tween>
            </div>
          </figure>
        </Reveal>
      </div>
    </EnvironmentCaptionedCon>
  )
}

export default EnvironmentCaptioned
