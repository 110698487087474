import React, { useRef } from "react"
import Hero from "./hero-env"
import { isMobile } from 'react-device-detect'
 
import EnvironmentCaptioned from "./captioned-env"
import EnvironmentBlurb from "./blurb-env"
import EnvironmentBlurbTwo from './blurbTwo-env'
import EnvironmentQuote from "./quote-env"
import EnvironmentCaptionedTwo from "./captionedTwo-env"
import MobNext from "../mobNext" 
import Next from '../next'

const EnvironmentPage = () => {
  const parent  = { width: `auto`, height: `100vh`, display: `flex`, overflowX: `scroll`, overflowY: `hidden`}
  const parentVert  = { width: `100vw`, height: `auto`, display: `block`}

  const onWheel = e => {
    const container = scrollRef.current;
    const containerScrollPosition = scrollRef.current.scrollLeft;

    container.scrollTo({
      top: 0,
      left: containerScrollPosition + e.deltaY,
    });
  };

  const scrollRef = useRef(null);

  return (
    <>
    {!isMobile && (
      <div style={parent} ref={scrollRef} className="horizontal" id="container" onWheel={onWheel}>
        <div className="panel">
          <Hero />
        </div>
        <div className="panel">
          <EnvironmentBlurb />
        </div>
        <div className="panel">
          <EnvironmentCaptioned />
        </div>
        <div className="panel">
          <EnvironmentCaptionedTwo />
        </div>
        <div className="panel">
          <EnvironmentBlurbTwo />
        </div>
        <div className="panel">
          <EnvironmentQuote />
        </div>
          <div className="panel">
            <Next />
          </div>
      </div>
    )}
    
    {isMobile && (
      <div style={parentVert} className="vertical">
        <Hero />
        <EnvironmentBlurb />
        <EnvironmentCaptioned />
        <EnvironmentCaptionedTwo />
        <EnvironmentBlurbTwo />
        <EnvironmentQuote />
        <MobNext />
      </div>
    )}
    </>
  )
}

export default EnvironmentPage
