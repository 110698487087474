import React from "react"
import SEO from '../components/seo'

import EnvironmentPage from "../components/EnvironmentPage"

const Environment = () => (
  <>
    <SEO 
      title="Environment Footprint" 
      description="Our eco-friendly laundry uses eco-friendly laundry detergents and hi-tech washing processes that reduce energy and water consumption"
      keywords={[` eco-friendly laundry`, `eco-friendly laundry detergent`]} 
    />
    <EnvironmentPage />
  </>
)

export default Environment
