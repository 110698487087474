import React from "react"
import styled from 'styled-components'
import media from '../../styles/media'
import { Reveal, Tween } from 'react-gsap'

const EnvironmentBlurbCon = styled.div`
  margin: 5em 6.2vw 7em;

  .heading {
    font-size: 20px;
    line-height: 40px;
    font-family: 'Merriweather', serif;
     
    color: black;
  }
  .body {
    font-size: 15px;
    line-height: 30px;
     
  }

  ${media.laptop`
    width: auto;
    height: 100vh;
    margin: auto 300px;
    display: flex;
    flex-direction: column;
    align-items: center; 
    justify-content: center;

    .text {
      width: 500px;
      margin: auto 150px;
    }

    .heading {
      font-size: 25px;
      line-height: 40px;
      letter-spacing: 0.01em;
    }
    .body {
      margin-top: 30px;
      max-width: 500px;
      font-size: 16px;
      line-height: 26px;
    }

  `}
`

const EnvironmentBlurb = () => {

  return ( 
    <>
    <EnvironmentBlurbCon>
      <div className="text">
        <Reveal>
          <Tween from={{ opacity: 0, paddingTop: '5vh' }} duration={1}>
            <p className="heading">Our offering represents one of the lowest carbon footprints available in this sector</p>
          </Tween>
        </Reveal>
        <Reveal>
          <Tween from={{ opacity: 0, marginTop: '5vh' }} duration={1} delay={.5}>
            <p className="body">
              One of our founding philosophies is to be environmentally sensitive, and this is reflected in our wash technology, fabric selection and our range of eco-friendly laundry detergents and chemical products.
            </p>
          </Tween>
        </Reveal>
      </div>
    </EnvironmentBlurbCon>
    </>
  )
}

export default EnvironmentBlurb
